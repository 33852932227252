@use '@angular/material' as mat;

@mixin page-header-typography($typography) {
    .page-header {
        .header-title-xx {
            font-size: mat.m2-font-size($typography, headline-2);
            font-weight: mat.m2-font-weight($typography, headline-2);
            line-height: mat.m2-line-height($typography, headline-2);
        }

        .header-title-x {
            font-size: mat.m2-font-size($typography, headline-3);
            font-weight: mat.m2-font-weight($typography, headline-3);
            line-height: mat.m2-line-height($typography, headline-3);
        }

        //Only used on old ticket entry forms.  Can be removed once they are removed
        .ticket-entry-field {
            .header-title-x {
                font-size: mat.m2-font-size($typography, headline-2);
                font-weight: mat.m2-font-weight($typography, headline-2);
                line-height: mat.m2-line-height($typography, headline-2);
            }
        }
    }
}
