/* For use in src/lib/core/theming/_palette.scss */
$fouriq-irth: (
    50 : #ffffff,
    100 : #004e7c,
    200 : #457aab,
    300 : #00264f,
    400 : #589681,
    500 : #87c7b0,
    600 : #296855,
    700 : #949494,
    800 : #e4e4e4,
    900 : #666666,
    A100 : #3c3c3c,
    A200 : #197fb0,
    A400 : #5caee2,
    A700 : #005380,
    contrast: (
        50 : #000000,
        100 : #ffffff,
        200 : #ffffff,
        300 : #ffffff,
        400 : #ffffff,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #000000,
        900 : #ffffff,
        A100 : #ffffff,
        A200 : #ffffff,
        A400 : #000000,
        A700 : #ffffff,
    )
);

