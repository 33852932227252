@use '@angular/material' as mat;
@mixin section-group-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .iq-section-group {
        .header {
            border-bottom: solid mat.m2-get-color-from-palette($primary, default) 1px;
        }

        .header.edit {
            border-bottom: solid mat.m2-get-color-from-palette($accent) 2px;
        }
    }
}
