//  This is included in the main styles.scss so that we can apply css styles to the nGrid components.


//  The @pebula/ngrid package has not been upgraded to support Angular 15 yet so it's paginator is using the legacy css classes.
//  Until that gets upgraded, we need to import the Legacy Paginator and initialize the legacy styles in App-theme.scss.
//  We don't use the MatPaginator at all so this does not affect anything in Exactix.
//  These styles are also required to fix the buttons & select used for the page navigation (which we cannot switch to legacy).
button.mat-mdc-paginator-navigation-previous, button.mat-mdc-paginator-navigation-next {
    border: none;
    padding: initial;
    margin: initial;
    width: 40px;
    height: 40px;
    background: transparent;
}
.iq-typography-default .mat-mdc-paginator, .iq-typography-default .mat-mdc-paginator-page-size .mat-mdc-select-trigger {
    font-size: 12px;
    .mat-mdc-select-value {
        font-size: 12px;
    }
}
.iq-typography-small .mat-mdc-paginator, .iq-typography-small .mat-mdc-paginator-page-size .mat-mdc-select-trigger {
    font-size: 10px;
    .mat-mdc-select-value {
        font-size: 10px;
    }
}
.iq-typography-large .mat-mdc-paginator, .iq-typography-large .mat-mdc-paginator-page-size .mat-mdc-select-trigger {
    font-size: 14px;
    .mat-mdc-select-value {
        font-size: 14px;
    }
}
.mat-mdc-paginator-range-actions .mat-mdc-paginator-page-size-select.mat-form-field-appearance-fill {
    //  Must increase this because it's using the new styles for the select (which set width to 64px) and includes
    //  a ton of padding and the selected checkmark (which the 64px does not account for)!
    //  Don't have a way to make the overlay autosize for just this paginator because it's in an overlay and there
    //  isn't anything specific to the paginator that we can select on.
    width: 100px;
}
