
@mixin section-group-padding($label-width) {
    .margin-no-label {
        padding-left: 20px;
    }

    .grid-label-content-links {
        display: grid;
        grid-template-columns: ($label-width - 10) + px auto max-content;
        grid-column-gap: 10px;
        align-items: center;
        padding: 3px 0;

        //Kind of a hack, but I didn't want to try and find everywhere that would need to be changed to fix this ATM.  So currently leave it as it was and then remove the style from all but the first instance of the class
        //Apply this style to all iq-caption classes
        & > .iq-caption {
            justify-self: flex-end;
            text-align: end;
        }
        //remove the style from all but the first instance of the class
        & > .iq-caption ~ .iq-caption {
            justify-self: initial;
            text-align: initial;
        }
    }

    .section-action-link {
        padding: 0 5px;
    }

    .grid-label-content {
        display: grid;
        grid-template-columns: ($label-width - 10) + px auto;
        grid-column-gap: 10px;
        align-items: center;
        padding: 3px 0;

        //Kind of a hack, but I didn't want to try and find everywhere that would need to be changed to fix this ATM.  So currently leave it as it was and then remove the style from all but the first instance of the class
        //Apply this style to all iq-caption classes
        & > .iq-caption {
            justify-self: flex-end;
            text-align: end;
        }
        //remove the style from all but the first instance of the class
        & > .iq-caption ~ .iq-caption {
            justify-self: initial;
            text-align: initial;
        }
    }
}
