
body {
    //  Angular 15 changed the padding on the content of the dialogs (none on top and too much on the bottom)
    .mdc-dialog__title+.mdc-dialog__content, .mdc-dialog__header+.mdc-dialog__content {
        padding: 20px 24px 20px 24px;
    }

    //  No idea why they would do this but Angular 15 sets a text color on the dialog content!  So all of the text looks
    //  dimmed out/brownish!  w...t...f...
    .mat-mdc-dialog-container .mdc-dialog__content {
        color: initial;
    }

    .mat-mdc-dialog-container, .mat-mdc-dialog-container .mdc-dialog__container, .mat-mdc-dialog-container .mdc-dialog__surface {
        //max-height: inherit;
        //min-height: inherit;
        //min-width: inherit;
        max-width: 100%;
    }

    .mat-mdc-dialog-title {
        margin-top: 0px;
    }
}

.iq-grab{
    cursor: grab;
}
.iq-grabbing{
    cursor: grabbing;
}

.dialog-fill-height {
    height: 100%;
    display: flex;
    flex-direction: column;

    .mat-mdc-dialog-title {
        flex-shrink: 0;
    }

    .mat-mdc-dialog-content {
        flex-grow: 1;
        max-height: unset;
    }

    .mat-mdc-dialog-actions {
        justify-content: center;
        flex-shrink: 0;
    }
}

.iq-device-size-phone {
    //  For phone devices, space-evenly is enough to space out the buttons (including centering when there is a single button).
    //  If we don't do this, the desktop css adds a bunch of margin which results in the buttons usually being stacked.
    .mat-mdc-dialog-actions.centered-dialog-buttons {
        justify-content: space-evenly !important;
        row-gap: 10px;
    }
}
.iq-device-size-desktop {
    .mat-mdc-dialog-actions.centered-dialog-buttons {
        justify-content: center !important;
        & > button, iq-icon-button {
            //  Don't use % - it does not allow the buttons to cause the dialog to expand and causes the buttons
            //  to overlap each other.
            margin-left: 2em !important;
            margin-right: 2em !important;
        }
    }
}

//  Can use addPanelClass() on MatDialogRef to dynamically set dialog to max size (with some buffer on the edges)
.dialog-max-size {
    height:90%;
    max-width:95% !important;
    width:95%;
}
