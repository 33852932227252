.pl10 {
    padding-left: 10px !important;
}

.pb5{
    padding-bottom: 5px !important;
}

.flex{
    display: flex;
}

//Just a plain old text overflow ellipsis cut off
.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

//Doesn't work on FF or IE....
//We need a better way to so this, but the directives I found would render the text then limit it (was horrible on affected service areas dialog/tab on a ticket).
//  So for the time being we are back to it only working on chrome and safari.  But needed to add '/*! autoprefixer: ignore next */'. See  https://github.com/postcss/autoprefixer/issues/776
.text-overflow-1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
}
//Doesn't work on FF or IE....
.text-overflow-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
}
//Doesn't work on FF or IE....
.text-overflow-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
}

//  Put this on a <ul> element to show the bullet images of <li> items as checkmarks
//  https://stackoverflow.com/a/51208163/916949
ul.checkmark-list-item {
    list-style: none;
    padding-left: 0;
    li {
        position: relative;
        padding-left: 1.5em; // space to preserve indentation on wrap
    }
    li:before {
        content: '✓';   // Using this as the image instead of svg because the svg is really thick.  Set this to '' and uncomment background to use svg instead.
        position: absolute;
        left: 0;         // place the SVG at the start of the padding
        width: 1em;
        height: 1em;
        //background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='18' height='18' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>") no-repeat;
    }
}
