@use '@angular/material' as mat;

@mixin tab-typography($typography) {
    .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
        font-size: mat.m2-font-size($typography, headline-4);
        font-weight: mat.m2-font-weight($typography, headline-4);
        line-height: mat.m2-line-height($typography, headline-4);
    }

    .mat-nav-list .mat-mdc-list-item {
        font-size: mat.m2-font-size($typography, headline-4);
        font-weight: mat.m2-font-weight($typography, headline-4);
        line-height: mat.m2-line-height($typography, headline-4);
    }
}
