/*resize column styles*/
.mat-mdc-table {
    width: 100%;

    &.resizing {
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: col-resize;
    }

    .mat-mdc-cell {
        span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .mat-mdc-header-cell {
        position: relative;

        &:not(:last-child) {
            .resize-holder {
                cursor: col-resize;
                width: 20px;
                height: 100%;
                position: absolute;
                right: -10px;
                top: 0;
                z-index: 1;
            }
        }
    }

    .mat-mdc-cell,
    .mat-mdc-header-cell {
        border-right: 1px solid rgba(0, 0, 0, 0.12);

        &:not(:nth-child(1)) {
            padding: 0 10px;
        }
    }
}

.mat-table-pagination-container {
    display: flex;
    align-items: baseline;
    background: white;

    mat-form-field {
        width: 56px;
        margin: 6px 4px 0 4px;
        font-size: 12px;
    }

    .go-to-label {
        margin: 0 4px;
        font-size: 12px;
    }

    .mat-option {
        background-color: white;
    }

    .mdc-notched-outline__leading, .mdc-notched-outline__trailing {
        border: unset !important;
    }

    .mat-mdc-paginator-page-size-select {
        min-width: 100px;
    }

    .mat-mdc-paginator-container {
        align-items: baseline !important;
    }
}

//Has to be here and not component css because this is done in a overlay outside of the comonent.
//  Also, not sure how helpful this really is because you can't close the overlay by clicking on the control, but it looks better when editing a contact of
//      and just selecting the contact types
.positionDropDownBelow {
    margin-top: 30px !important;
}

//  Angular 15 included changes to the Material Components that completely restyled the MatFormField controls.
//  They now default to a ridiculous amount of padding and either a gray fill background or an outline.
//  These styles revert all of that and a few other things.
//  Need to over specify some of these css classes because we are trying to override some values that Angular
//  is defining.  And they are doing this in their "theme" configuration so all of their css rules have the
//  themes defined in them!  So have to add a bunch of css classes to make ours more selectable than theirs.
mat-form-field.mat-mdc-form-field {
    //  Clear the background - Angular set the entire background to gray (including the floating placeholder!)
    .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--filled {
        background-color: inherit;
        .mat-mdc-form-field-focus-overlay {
            background-color: inherit;
        }
    }

    //  This is needed to separate multiple rows of these controls.  Not sure this is the best way...
    //  row-gap on the parent container would be better...
    padding-bottom: 5px;

    //  Remove the extra padding from the left & right on the inside of the mat-form-field
    .mdc-text-field {
        padding: 0px;
    }

    .mat-mdc-form-field-infix {
        //  Angular hardcodes this to 180px which makes it difficult to properly size the input against the form field.
        width: auto;
    }
}
mat-form-field.mat-mdc-form-field:not(.no-floating-placeholder) {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
        //  Remove the extra padding from the top/bottom
        .mat-mdc-form-field-infix {
            padding-top: 20px !important;       //  was 24px
            padding-bottom: 2px !important;     //  was 8px
            min-height: 46px !important;        //  was 56px
        }

        //  Move the placeholder label up slightly (there's a bunch of wasted space above it...)
        //  This is slightly different when used on an EntryForm and is set differently in EntryFormShared.scss
        /*label.mdc-floating-label.mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
            top: 30px;                          //  was 28px
        }*/
        label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
            //top: 24px !important;               //  was 28px
            top: 28px;                          //  was 28px
        }
    }
}
mat-form-field.mat-mdc-form-field.no-floating-placeholder {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
        //  Remove the extra padding from the top/bottom
        .mat-mdc-form-field-infix {
            padding-top: 0px !important;       //  was 24px
            padding-bottom: 2px !important;     //  was 8px
            min-height: 23px !important;        //  was 56px
        }
    }
}

.mdc-menu-surface.mat-mdc-autocomplete-panel.mdc-menu-surface--open {
    //  Angular 15 added padding to the top & bottom of the autocomplete panel...
    padding-top: initial;
    padding-bottom: initial;
}

//  Needed for the Ticket Type dropdown or the colors only color the text and do not fill the dropdown consistently
//  And this is actually contained in an overlay container so it's not inside the mat-form-field.
.colored-mat-option {
    .mdc-list-item__primary-text {
        width: 100%;

        > :first-child {
            display:inherit;
            padding-left:5px;
            width:100%
        }
    }
}

/* mat-paginator */
.mat-mdc-paginator { //Our background may not be white, so we want to to show whatever the backgroud color is
    background-color: transparent;
}


/**********Drag and drop values.  Used throught the system, if that needs changed to only be in specific places then update this**************/
/* Animate items as they're being sorted. */
.cdk-drop-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
    opacity: .5;
}
/**********End Drag and drop**************/


//  Put this class on an <autocomplete> tag to pick this up.  These are the same styles used on ticket entry form.
//  It sizes everything better and makes the rows auto size.
.iq-autocomplete, .iq-ticket-entry, .iq-entry-form {
    &.mdc-menu-surface.mat-mdc-autocomplete-panel {
        //Width is done by setting the 'panelWidth' property to auto now.  That way the autocomplete popup will autosize larger or smaller based on what it can do based on the screen size, not input size
        //min-width: 700px; // style left her incase people don't like the auto sizing. min-width needed when we have an autocomplete on an input that is not very wide (like Company ID).
        max-height: 20em; //  default is 256px which makes it scroll most of the time.  There is no setting for "bottom of view"...
    }
    //  This changes the default font and spacing used by plain (non-templated) autocompletes on ticket entry.
    //  The Material defaults have a MASSIVE amount of padding in between items!
    .mat-mdc-option.mat-mdc-option-active {
        background-color: rgba(0, 0, 0, 0.08) !important;
    }

    .mat-mdc-option {
        font-size: 14px !important; //Need to be important so it will work for mat-selects too because they change this property
        height: auto !important; //Need to be important so it will work for mat-selects too because they change this property
        line-height: unset !important; //Need to be important so it will work for mat-selects too because they change this property
        padding: 5px 16px;
        min-height: unset;

        .item {
            padding: 0 4px; //Add a little more padding for these (they are autocomplete items) so that they are indented alittle more than the actions (which will be indented at 16px)
        }
    }
}

//  Along with setting the panelClass to iq-ticket-entry on a MatSelect, if it also has the "autosize" class, this will cause the overlay panel
//  to auto size itself.  Allows a narrow MatSelect to show an expanded overlay panel.  Needed mostly because Angular 15 added the checkmark display for
//  the selected item and did not think about how that would break the overlay panel!
.cdk-overlay-pane:has(.iq-ticket-entry.autosize) {
    width:unset !important;
}


//  Put "allow-wrap" on a map-top to allow the content to wrap to another line.
//  Angular's default is to add "white-space:nowrap" in it's ".mat-option" css class.
.allow-wrap.mat-mdc-option {
    white-space: initial;
}

//  Used on a mat-select element that has a color defined directly on it.  Needed because the material styles
//  hardcode a black color...
//  "not disabled" or the background color will be disabled but the color will not be the dimmed out black.
.inherit-color:not(.mat-mdc-select-disabled) {
    .mat-mdc-select-value {
        color: inherit !important;
    }
}

.mtx-calendar-header-date-time {
    //  Needed for the @ng-matero/extensions date picker to separate the date from the time in the pop-up
    display:flex;
    justify-content:space-between;

    //  By default, when the calendar is being displayed and we also prompt for the time, the time is shown dimmed out
    //  and it shows BOTH AM & PM.  It's dimmed to put the focus on the date which is what is being changed.  But that's
    //  kind of obvious since there is a calendar being displayed.  And shownig both am & pm makes it impossible to tell
    //  which one is currently selected.  This change the time to not be dimmed and it hides the am/pm indicator that
    //  is not selected.
    .mtx-calendar-header-time:not(.active) {
        opacity: unset;             //  Don't dim the time

        .mtx-calendar-header-ampm:not(.active) {
            display: none;          //  Hide the am/pm indicator that is not active
        }
        .mtx-calendar-header-ampm-container {
            align-self: center;     //  Center so that the remaining indicator lines up better with the time
        }
    }
}
.mtx-calendar-header {
    button {
        //  This is needed for the mtx-datetime-picker.  Without it, Angular default button height of 36px screws up the
        //  layout of the time picker page and causes the OK/Cancel buttons to be off the bottom of the screen.
        height: unset !important;
    }
}

.mat-icon {
    //  Angular 14 added "overflow:hidden" to this and it jacks up the display of the ticket version indicators in the ticket list.
    //  That can be fixed by changing those mat-icon elements to have a style of "flex-shrink:0", but went with this global fix
    //  because I'm not sure what else Angular's change may have affected.  This reverts it back to how it was pre-14.
    overflow:inherit !important;
}


//  Slightly lighter background for mat-chips
.mat-mdc-chip.mat-mdc-standard-chip {
    background-color: #eaeaea !important;
}

//  Angular 15 has an 8px margin-left on inputs linked to a matChip!  It causes the placeholder text to get cut-off!
//  The margin should be fine normally but if the mat-chip-grid is not inside the same mat-form-field as the input, it
//  causes the placeholder text to get cut-off.  If that happens, just put the "no-margin" class on the <input>
//  This happens with the AutocompleteFilter.component (i.e. a column header filter on the Place column of ticket list).
input.mat-mdc-chip-input.no-margin {
    margin-left: initial !important;
}

//  Angular has a style on "mdc-button" which sets everything to center.  Which messes up the buttons used inside a menu.
.mat-mdc-menu-content,  {
    .mdc-button {
        justify-content: initial;
    }
}

//  mat-button-toggle used on the ticket dashboard for the phone view
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    border:none !important;
    .mat-button-toggle {
        //  border width specified in _actionable-items-theme.scss
        border-radius: 8px;
        .mat-button-toggle-label-content {
            //  Reset all the crazy padding Angular adds
            padding: 0px;
            line-height: inherit;
        }
        .mat-button-toggle-button {
            padding: 5px 8px 5px 8px;
        }
    }
}

.mat-mdc-slide-toggle {
    button.mdc-switch {
        .mdc-switch__ripple {
            //  Angular's default width/height for this (used on a slide-toggle) is 48px!  Which makes the clickable area overlap with other controls!
            //  This sizes the clickable area to be the same as the parent.  Which is the the correct size.
            width: 100%;
            height: 100%;
        }
    }
}

.mat-mdc-menu-panel {
    //  Angular sets this to 280px for some reason which messes up a couple places - including the Actions menus after saving a ticket.
    max-width: unset !important;
}

label.mdc-floating-label {
    font-style: italic;// !important;
    padding-right: 5px;     //  Needed or a trailing "d" gets cut off when the label is italicized!
}

//  Angular 15 changed checkboxes and radios from taking up 16px to 40px!  This reduces some of the extra padding to make them
//  size similarly to how they did before.
.mat-mdc-checkbox .mdc-checkbox {
    margin: -6px !important;
}
.mat-mdc-radio-button .mdc-radio {
    margin: -6px !important;
}

body {
    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        padding: 0px 5px;

        .mat-mdc-form-field-error {
            display: flex;      //  Fixes vertical spacing to remove extra space above the error
        }
    }
}

.mat-mdc-form-field-hint {
    color: gray;
}
