.page-header {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;

    /*min-height: 110px;*/
    .header-content {
        flex-grow: 1;
        padding: 10px;

        &.tab-align {
            padding: 10px 10px 10px 20px;
        }

        .header-title-xx {
            padding-bottom: 20px;
        }
    }
}
