/*These are a place to do styles that needed for stuff that ticket creation related, but the styles need to be created outside of the ticket details component.
    Basically anything that has ::ng-deep needs to be removed and moved to here.  We SHOULD NOT use ng-deep because one the ng-deep style is applied to a dropdown result, etc
        it will then be applied to EVERY dropdown result shown from that point on until the person refreshes.  So ATM if a user goes to ticket creation the ng-deep style is created
        so now anywhere they go in the app will have the styles created at that point.
    Also using ng-deep will create a BUNCH of the same style in the css (one for every control that uses the style), so if you look at inspector you will see the same class with the
        same styles repeated over and over.  We don't want this because it bloats our css
    i.e. when styling select autocomplete results, or drop-down results.
    Global ticket creation styles are in /Styles/TicketCreation.scss. */

@import '../../../Shared/EntryFields/Styles/EntryFormShared.scss';

//  ** Styles shared by all entry-forms/fields (i.e. shared with project entry) should be put in EntryFormShared.scss
//  Only put stuff that is specific to ticket entry in here.

.hide-dialog {
    display:none
}

.iq-ticket-side-content-container {

    &.mat-mdc-tab-group {
        .mat-mdc-tab-header {
            margin-bottom: 10px;
        }
    }

    &.mat-mdc-tab-group.hidden {
        .mat-mdc-tab-header {
            display: none;
            margin-bottom: 0px;
        }
    }

    .mat-mdc-tab-body {
        padding: 0 10px 10px 10px;
    }
}

.mat-mdc-tab-body {
    //overflow: hidden !important;       //  The content should scroll, not the tab body - messes up the ticket entry page
    display:flex !important;
    flex-direction: column !important;
    .mat-mdc-tab-body-content {
        /*overflow: hidden !important;*/
        display:flex;
        flex-direction:column;
        flex-grow: 1;
    }
}

//Has to be here because it spans multiple components
.ticket-entry-tab-header.hide-tab-header > .mat-mdc-tab-header:first-child {
    display: none;
}

//has to be here because the html for this is added dynamically from the DB
.ticket-details .disclaimer-header {
    text-align: center;
}
//has to be here because the html for this is added dynamically from the DB
.ticket-details .disclaimer-content {
    padding: 0 20px 20px 20px;
    overflow: auto;
}

.mat-mdc-tab-body-wrapper {
    //  Don't use height:100% here, it causes performance issues with iOS 14.4!
    flex-basis:100%;
}


//  Classes to help when building a color code matrix in a Ticket Saved Dialog
//  This is used by the DigSafe ticket saved step (step #2 for a web user).  The styles are referenced by the dynamic
//  html of the step so must be set here or they will not be found.
.color-code-row {
    display:flex;
}
.color-code-item {
    display: flex;
    padding: 10px;
    align-items: center;
}

.iq-device-size-desktop, .iq-device-size-phone.iq-device-landscape {
    .color-code-item {
        flex-basis: 50%;
    }
}
.iq-device-size-phone.iq-device-portrait {
    .color-code-row {
        flex-wrap: wrap;
    }
    .color-code-item {
        flex-grow:1;
    }
}

.color-code-box {
    width: 35%; //  % so it will format on iPad
    height: 40px;
    font-weight: bold;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.color-code-desc {
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
}
