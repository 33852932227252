@use '@angular/material' as mat;

@mixin page-header-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    $background-color: mat.m2-get-color-from-palette($primary, default, .2);//Add an opacity so it doesn't blend in with the un selected tabs.  Maybe use a new color for one of them?
    $background-font-color: mat.m2-get-color-from-palette($primary, 'default-contrast');

    .page-header-background-color {
        background-color: $background-color;
        color: $background-font-color;
    }

    .page-header {
        background-color: $background-color;
        color: $background-font-color;

        .header-content {

            .header-title-xx {
                color: mat.m2-get-color-from-palette($accent); //We want the font to be a differnt color than the contrast of the primary
            }

            .header-title-x {
                color: mat.m2-get-color-from-palette($accent);
            }
        }
    }
}
