@use '@angular/material' as mat;
@mixin main-nav-theme($theme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);

    .mat-sidenav-container {

        .mat-drawer {
            background-color: mat.m2-get-color-from-palette($primary);
            color: mat.m2-get-color-from-palette($primary, "default-contrast");

            .mat-mdc-nav-list {
                .mdc-list-item:hover {
                    background-color: mat.m2-get-color-from-palette($accent) !important;
                    .mdc-list-item__primary-text {
                        color: mat.m2-get-color-from-palette($accent, 'default-contrast');
                    }
                }
                .mdc-list-item.active-link:not(:hover) {
                    background-color: mat.m2-get-color-from-palette($primary, "darker") !important;
                    .mdc-list-item__primary-text {
                        color: mat.m2-get-color-from-palette($primary, "lighter-contrast");
                    }
                }
                .mdc-list-item:not(.active-link):not(:hover) {
                    background-color: mat.m2-get-color-from-palette($primary, "lighter");
                    .mdc-list-item__primary-text {
                        color: mat.m2-get-color-from-palette($primary, "darker-contrast") !important;
                    }
                }
                .mdc-list-item {
                    border: 1px solid mat.m2-get-color-from-palette($primary);
                    border-top: 0px;
                    .mdc-list-item__content {
                        display: flex;
                        height: inherit;
                        align-items: center;
                        .mdc-list-item__primary-text {
                            display: flex;
                            column-gap: 10px;
                            align-items: center;
                            white-space: break-spaces;
                        }
                        .menu-icon {
                            min-width: 20px;
                        }
                    }
                }
            }
        }

        .mat-sidenav-content {
            background-color: white;
        }
    }

    .iq-phone-main-menu {
        .mat-mdc-nav-list {
            .mdc-list-item.active-link {
                background-color: mat.m2-get-color-from-palette($primary, "darker");
                .mdc-list-item__primary-text {
                    color: mat.m2-get-color-from-palette($primary, "darker-contrast");
                }
            }
        }
    }
}
