@use '@angular/material' as mat;

@mixin mat-icons-typography($typography) {
    .material-icons {
        font-size: mat.m2-font-size($typography, body-2);
        font-weight: mat.m2-font-weight($typography, body-2);
        line-height: mat.m2-line-height($typography, body-2) !important;
        height: mat.m2-font-size($typography, body-2);
        width: mat.m2-font-size($typography, body-2);
    }
}
