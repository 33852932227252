//  Shared styles for "entry forms" - i.e. Ticket and Project entry.
//  Ideally, use a general class name like "iq-entry-form" in case we make other forms like this somewhere else.
//  But handles all of the "iq-ticket-entry" classes so we don't have to change them all.

//  *** Autocomplete styles are in MaterialStyleOverrides which is included globally.
//  They key off: .iq-autocomplete, .iq-ticket-entry, .iq-entry-form

.iq-ticket-entry-form, .iq-entry-form {
    .iq-section-group {
        //  Both of these are needed so that placeholder text on matInput controls do not overlap the header text
        .header.edit {
            //margin-bottom: 10px !important;
        }
        .header:not(.edit) {
            margin-bottom: 5px !important;
        }

        .content {
            //padding-top: 5px !important;
            padding-left: 20px !important;
        }
    }
}
.iq-device-size-phone.iq-device-portrait {
    .iq-ticket-entry-form, .iq-entry-form {
        .iq-section-group {
            .content {
                //  Override the default of 20px for phone portrait
                padding-left: 0px !important;
            }
        }
    }
}

.iq-ticket-entry-section, .iq-entry-form-section {
    .iq-section-item-view, .iq-section-item-edit {
        label, div.label {
            padding-right: 10px;
        }
    }

    .iq-section-group {
        div.label {
            padding-right: 10px;
        }
    }

    //  Need to over specify some of these css classes because we are trying to override some values that Angular
    //  is defining.  And they are doing this in their "theme" configuration so all of their css rules have the
    //  themes defined in them!  So have to add a bunch of css classes to make ours more selectable than theirs.
    mat-form-field {
        .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
            .iq-entry-form-field-content {
                //  This is a div added by EntryFormFieldComponent to give some padding when we are coloring the field
                //  (so that the text is not right up against the background color we are setting).
                padding: 2px 5px 2px 5px;
            }
            .iq-entry-form-field-content:has(.mat-mdc-select) {
                //  This is necessary to make the MatSelects the same height as an input.
                //  Can see the difference on the FL Site Info section with the Footprint.
                padding-top: 0px;
            }

            //  Move the placeholder label slightly to match up to the background highlighting we do (including
            //  the padding specified by iq-entry-form-field-content).
            label.mdc-floating-label.mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
                top: 33px;                          //  was 28px
                left: 5px;
            }
            label.mdc-floating-label.mat-mdc-floating-label.mdc-floating-label--float-above {
                left: 5px;
            }

            //  For some reason Angular 15 has a default style on *ALL* ".mdc-text-field__input" classes that sets the height to 28px.
            //  But then, they have another style that sets height to auto for <input> controls!  But that style is not being picked up
            //  but our "iq-entry-field-multiple-select-autocomplete" component.  So this is necessary to get those chip lists to size the
            //  same as all of the other components.
            .mat-mdc-chip-input.mdc-text-field__input {
                height: auto;
            }
        }
    }

    .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
        //  This leaves room inside the input for the x and downarrow.  Otherwise, long text will overlap them and ng-select does nothing about it!
        width: calc(100% - 40px);
    }

    //  This should not be necessary.  But on the ticket entry pages, the mat-selects suddenly started showing the arrow at the top
    //  of the control instead of the middle!  Angular does this automatically if there is no label (it's a css class it looks for),
    //  but maybe something about how we are building the iq-entry-form-field component (and injecting the label) is causing the
    //  mat-select to not see it and it's not setting something correctly...
    .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
        transform: none;
    }

    .mat-form-field-disabled {
        input, .mat-mdc-select, textarea {
            background-color: #F2F2F2 !important;
        }
    }
}

.hide {
    display: none !important;
}
