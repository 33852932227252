.iq-device-size-phone {

    .iq-response-select {
        max-width: 90vw !important; //Need to do this so that we get as much width as we can because the text in the responses is looooong
    }

    .mat-mdc-select-panel {
        //Needed because the the select options will get cut off on smaller phones if turned sideways.
        //  The default size is 256, and some screens are close to that size so when the options are shown it won't fit correctly
        max-height: 50vh !important;

        &.iq-multi-select {
            //Have to do this because it calculates the size wrong on a multi-select and it runs off the screen
            max-width: 95vw !important;
            min-width: unset !important;
        }
    }

    //  This stuff is all used for phone headers.  Some of which is in the PhoneHeaderComponent but some is also just done with a
    //  manual mat-toolbar in the component (which shows a gray header).
    //  So these classes must be defined globally.
    mat-toolbar {
        display: flex;
        justify-content: space-between;
        height: 50px;
        align-items: center;
        padding:unset;
        gap:1em;
        white-space:break-spaces;
    }

    //  The flex-shrink's used here seem to give the best balance of shrinking or not in order to get the 3 sections to wrap themselves when possible
    //  Specific problem pages:
    //  * DigSafe affected service area flyout for a web user - middle section has 3 words in it
    //  * AZ Web user ticket saved flyout - has extremely large middle section and single words for left/right.
    .iq-phone-header-left {
        display: flex;
        overflow: hidden;
        flex-shrink:1;
    }
    .iq-phone-header-left:has(iq-phone-button) {
        //  Expand these to height 100% to give more click-area
        height: 100%;
        iq-phone-button {
            height: 100%;
        }
    }

    .iq-phone-header-center {
        display: flex;
        overflow: hidden;
        text-align:center;
        flex-shrink:3;
    }
    .iq-phone-header-center:has(iq-phone-button) {
        height: 100%;
        iq-phone-button {
            height: 100%;
        }
    }

    .iq-phone-header-right {
        display: flex;
        overflow: hidden;
        flex-shrink:1;
    }
    .iq-phone-header-right:has(iq-phone-button) {
        height: 100%;
        iq-phone-button {
            height: 100%;
        }
    }

    button.mat-mdc-menu-item {
        .action-menu-option {
            fa-icon {
                //  Add some padding between an icon and the button text when used in a menu
                padding-right: 10px;
            }
        }
    }
}
