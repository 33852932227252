@use '@angular/material' as mat;
@use '../../Variables.scss' as *;

@mixin primary-color-theme($primaryTheme, $secondaryTheme, $grayFontTheme) {
    // Extract whichever individual palettes you need from the theme.
    $primary: map-get($primaryTheme, primary);
    $accent: map-get($primaryTheme, accent);
    $secondary: map-get($secondaryTheme, primary);

    $primary-warn: map-get($primaryTheme, warn);

    $grayFontPrimary: map-get($grayFontTheme, primary);
    $grayFontAccent: map-get($grayFontTheme, accent);

    .primary-background {
        background-color: mat.m2-get-color-from-palette($primary);
        color: mat.m2-get-color-from-palette($primary, "default-contrast") !important;
    }

    .secondary-background {
        background-color: mat.m2-get-color-from-palette($secondary);
        color: mat.m2-get-color-from-palette($secondary, "default-contrast") !important;
    }

    .accent-background {
        background-color: mat.m2-get-color-from-palette($accent);
        color: mat.m2-get-color-from-palette($accent, "default-contrast") !important;
    }

    .primary-color, .link-primary-color {
        color: mat.m2-get-color-from-palette($primary);
    }

    .primary-color-dark {
        color: mat.m2-get-color-from-palette($primary, "darker");
    }

    .primary-color-light {
        color: mat.m2-get-color-from-palette($primary, "lighter");
    }

    .link, .accent-color {
        color: mat.m2-get-color-from-palette($accent);
    }

    .accent-color-contrast {
        color: mat.m2-get-color-from-palette($accent, "default-contrast") !important;
    }

    .primary-border-color {
        border-color: mat.m2-get-color-from-palette($primary);
    }

    .secondary-border-color {
        border-color: mat.m2-get-color-from-palette($secondary);
    }

    .gray-border-color {
        border-color: mat.m2-get-color-from-palette($grayFontPrimary) !important;
    }

    .red-border-color {
        border-color: red !important;
    }

    .gray-primary-text {
        color: mat.m2-get-color-from-palette($grayFontPrimary);
    }

    .empty-values {
        font-style: italic;
        color: mat.m2-get-color-from-palette($grayFontPrimary);
    }

    .iq-warn, .iq-warn .iq-section-header {
        color: mat.m2-get-color-from-palette($primary-warn);
    }

    .iq-primary-bottom-border {
        border-bottom: solid mat.m2-get-color-from-palette($primary, default) 1px;
    }

    .iq-section-header {
        color: mat.m2-get-color-from-palette($primary);
        border-bottom: solid mat.m2-get-color-from-palette($secondary) 2px;
        margin-bottom: 3px;
        padding: 2px;
    }
    //The material stuff doesn't use the contrast color for text colors (they use black).  So override that to always use the contrast
    .mat-toolbar.mat-primary {
        color: mat.m2-get-color-from-palette($primary, "default-contrast") !important;
    }

    .mat-toolbar.mat-accent {
        color: mat.m2-get-color-from-palette($accent, "default-contrast") !important;
    }

    //  Put this class on a tooltip to style it.
    //  Applies to tooltips created from the Tooltip directive in Shared/Tooltips/TooltipModule.
    //  Search for "[tooltip]" for places where it's used.
    .iq-tooltip {
        background-color: white; //  Default background is black with white text
        color: unset;
        border: 1px solid mat.m2-get-color-from-palette($primary); //  Border around the tooltip
        max-width: unset; //  default is 200px...
    }

    .iq-tooltip.tooltip-right::after {
        //  This changes the color and size of the arrow
        margin-top: -7px;
        border-width: 7px;
        border-color: transparent mat.m2-get-color-from-palette($primary) transparent transparent;
    }
}
